import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6e3aab39&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e3aab39",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WEvents from '@/components/WEvents.vue'
import WSwitch from '@/components/WSwitch.vue'
import WTopBar from '@/components/WTopBar.vue'
autoInstallComponents(component, {WEvents,WSwitch,WTopBar})
