<template>
  <div>
    <w-top-bar>Eventos</w-top-bar>
    <div class="h-full p-5 bg-gray-200">
      <div class="flex items-center justify-between h-12 px-1">
        <!-- <div class="py-2 text-gray-900">Productos</div> -->
        <div class="flex items-center text-xs">
          <w-switch
            :value="pastEvents"
            @input="pastEvents = !pastEvents"
          ></w-switch>
          <span>Mostrar Anteriores</span>
        </div>
        <router-link
          :to="{ name: 'inscriptions-id', params: { id: 'new' } }"
          class="text-sm text-blue-500 cursor-pointer hover:text-blue-600"
          >Nuevo Evento</router-link
        >
        <!-- <button @click="fake()" class="text-sm text-blue-500 cursor-pointer hover:text-blue-600">Add Fake Evento</button> -->
      </div>
      <w-events :items="events" />
    </div>
  </div>
</template>

<script>
import { makeFindMixin } from "feathers-vuex";
import { mapActions } from "vuex";

export default {
  name: "inscriptionsList",
  data() {
    return {
      pastEvents: false,
    };
  },
  mixins: [makeFindMixin({ service: "products", name: "events" })],
  computed: {
    eventsParams() {
      let past = {};
      // if (!this.pastEvents)
      //   past = {
      //     dateTS: {
      //       $gt: new Date().getTime(),
      //     },
      //   };

      return {
        query: {
          type: "streamings",
          subproduct: { $ne: true },
          $sort: {
            date: -1,
          },
          ...past,
        },
      };
    },
  },
  methods: {
    ...mapActions("inscriptions", { create: "create" }),
  },
};
</script>

<style lang="scss" scoped></style>
