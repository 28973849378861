<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div
          class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                >
                  Titulo
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                >
                  Fecha y Hora
                </th>
                <!-- <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                >
                  Información
                </th> -->
                <th class="px-6 py-3 bg-gray-50"></th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-300">
              <tr
                :class="{ 'opacity-50 hover:opacity-100': past }"
                v-for="event in items"
                :key="event._id"
              >
                <td class="px-6 py-4 whitespace-no-wrap">
                  <div class="">
                    <div
                      class="flex items-center text-sm font-medium leading-5 text-gray-900"
                    >
                      <div>{{ event.name }}</div>
                    </div>
                    <div
                      class="max-w-xs text-sm leading-5 text-gray-500 truncate"
                    >
                      {{ event.subtitle }}
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap">
                  <div class="text-sm leading-5 text-gray-900">
                    <span v-if="!event.allMonth">
                      {{ $moment(event.date).format("dddd D") | capitalize }}
                    </span>
                    <div class="flex items-center" v-else>
                      <div
                        v-tooltip="'Evento con frecuencia semanal'"
                        class="mr-1"
                      >
                        <svg
                          class="w-4 h-4 text-blue-500"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                          />
                        </svg>
                      </div>
                      {{ $moment(event.date).format("dddd") | capitalize }}s
                    </div>
                  </div>
                  <div class="text-sm leading-5 text-gray-500">
                    {{ $moment(event.date).format("HH:mm") }}hs.
                  </div>
                </td>
                <!-- <td class="px-6 py-4 text-sm whitespace-no-wrap">
                  <div class="text-sm text-gray-500">
                    {{ event.totalParticipants }} Participantes
                  </div>
                </td> -->
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap"
                >
                  <!-- <a href="#" class="text-indigo-600 hover:text-indigo-900">Edit</a> -->
                  <div class="relative">
                    <v-popover offset="5" placement="auto" class="inline-block">
                      <span class="rounded-md shadow-sm">
                        <button
                          type="button"
                          class="inline-flex justify-center w-full px-2 py-1 text-xs font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                          id="options-menu"
                          aria-haspopup="menu"
                          aria-expanded="true"
                        >
                          Acciones
                          <!-- Heroicon name: chevron-down -->
                          <svg
                            class="w-5 h-5 ml-2 -mr-1"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </span>

                      <template slot="popover" class="p-0 m-0">
                        <div
                          class="p-1 py-1 text-xs text-left hover:bg-gray-100"
                        >
                          <router-link
                            class="flex items-center cursor-pointer"
                            :to="{
                              name: 'events-id',
                              params: {
                                id: event._id,
                              },
                            }"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>

                            Editar evento</router-link
                          >
                        </div>
                        <div
                          class="p-1 py-1 text-xs text-left hover:bg-gray-100"
                        >
                          <router-link
                            class="flex items-center cursor-pointer"
                            :to="{
                              name: 'events-id',
                              params: { id: event._id },
                            }"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                              />
                            </svg>

                            Ver Inscriptos</router-link
                          >
                        </div>
                        <div
                          class="flex items-center p-1 py-1 text-xs text-left border-t border-gray-200 cursor-pointer hover:bg-gray-100"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                            />
                          </svg>
                          Notificaciones
                        </div>
                        <a
                          :href="domain + '/' + event.shorter"
                          target="_blank"
                          class="flex items-center p-1 py-1 text-xs text-left border-t border-gray-200 cursor-pointer hover:bg-gray-100"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                            />
                          </svg>

                          Página de Inscripción
                        </a>

                        <div
                          class="flex items-center p-1 py-1 text-xs text-left border-t border-gray-200 cursor-pointer hover:bg-red-500 hover:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>

                          Eliminar
                        </div>
                      </template>
                    </v-popover>
                  </div>
                </td>
              </tr>

              <!-- More rows... -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    past: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      domain: "https://domingosconclaudio.com",
    };
  },
};
</script>

<style lang="scss" scoped></style>
